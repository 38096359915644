:root {
  /* Primary */
  --white: #fff;
  --black: #000;
  --signature: #0e103e;

  --tobii-ocean-blue: #0071eb;
  --tobii-frost-blue: #f2f8ff;
  --tobii-frost-blue-10b: #ebf1f8;
  --tobii-frost-blue-20b: #e5ebf2;
  --tobii-frost-blue-30b: #dee4eb;
  --tobii-peach: #fd6f60;
  --tobii-violet: #8018d8;
  --tobii-ocean-blue-90b: #00172f;
  --tobii-ocean-blue-60b: #002d5e;
  --tobii-ocean-blue-30b: #004fa5;
  --tobii-ocean-blue-30w: #4d9cf1;
  --tobii-ocean-blue-60w: #99c6f7;
  --tobii-light-gray: #e8e8e8;

  --tobii-link-color: var(--tobii-ocean-blue);
  --tobii-link-color-hover: var(--tobii-ocean-blue-30b);

  /* Additional */
  --green: #44a634;
  --orange: #cf6925;
  --red: #c72924;

  /* CTA */
  --cta: var(--tobii-ocean-blue);

  /* Foreground */
  --foreground-1: var(--black);
  --foreground-2: rgb(45 55 62 / 76%);
  --foreground-3: rgb(56 61 86 / 40%);
  --foreground-i1: var(--white);
  --foreground-i2: rgb(255 255 255 / 70%);
  --foreground-i3: rgb(255 255 255 / 30%);
  --foreground-disabled: rgb(56 61 86 / 40%);
  --foreground-idisabled: rgb(255 255 255 / 30%);

  /* Background */
  --background-1: var(--white);
  --background-2: #ededed;
  --background-3: #fafafa;

  --white-transparent-5: rgb(255 255 255 / 5%);
  --white-transparent-10: rgb(255 255 255 / 10%);
  --white-transparent-25: rgb(255 255 255 / 25%);
  --white-transparent-50: rgb(255 255 255 / 50%);
  --white-transparent-75: rgb(255 255 255 / 75%);
  --white-transparent-90: rgb(255 255 255 / 90%);
  --white-transparent-95: rgb(255 255 255 / 95%);
  --black-transparent-5: rgb(0 0 0 / 5%);
  --black-transparent-10: rgb(0 0 0 / 10%);
  --black-transparent-15: rgb(0 0 0 / 15%);
  --black-transparent-25: rgb(0 0 0 / 25%);
  --black-transparent-50: rgb(0 0 0 / 50%);
  --black-transparent-75: rgb(0 0 0 / 75%);
  --black-transparent-90: rgb(0 0 0 / 90%);
  --black-transparent-95: rgb(0 0 0 / 95%);

  /* Strokes */
  --stroke-1: rgb(114 152 187 / 20%);
  --stroke-2: rgb(114 152 187 / 30%);
  --stroke-i1: rgb(200 207 240 / 15%);

  --overlay: var(--tobii-ocean-blue-90b);
  --table-row-gray: #f4f4f5;
  --start-page-content-grid-background: #fafafa;

  /* Navigation */
  --white-smoke: #f5f5f5;
}
